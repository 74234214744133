import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Grid,
  FormControlLabel,
  IconButton,
  Checkbox,
  CheckboxProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  Collapse,
  Radio,
  RadioProps,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  OutlindedFieldContainer,
  StyledButton,
  StyledFormGroup,
  StyledTextField,
  StyledGridContainer,
  StyledOtherContainer,
  StyledCheckBoxContainer,
  SectionTitle,
  CheckBoxTitle,
  StyledAlert,
  StyledRadioGroup,
  StyledRadioContainer,
  StyledRadioFormLabel,
  StyledSexRadioGroup,
  StyledFormContainer,
  Text,
  StyledLink,
} from "./styles";

import { FormTitle } from "../FormTitle";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import { useForm, Controller } from "react-hook-form";
import { colors } from "../../styles/variables";
import { getFiles } from "../toBase64File";
import { callEmailAPI } from "../emailSend";
import { medicalHistory } from "./data";
import { makePdf } from "./patientFormPdf";

const GreenCheckbox = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: colors.primary,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: colors.primary,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    top: {
      color: `${colors.light}`,
      animationDuration: "550ms",
      width: "1rem !important",
      height: "1rem !important",
      margin: "0 0.5rem 0 0",
    },
  })
);
interface Size {
  width: number;
  height: number;
}

const PatientForm = () => {
  const [size, setSize] = useState<Size>();
  const [gender, setGender] = useState({
    male: "Male",
    female: "Female",
    nonBinary: "Non binary",
  });
  const [hasPrivateInsurance, setHasPrivateInsurance] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openSucceed, setOpenSucceed] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFileSize, setOpenFileSize] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const { register, handleSubmit, errors, control } = useForm();
  const classes = useStyles();

  const mobileProps = {
    maxLength: 10,
  };
  const nameProps = {
    maxLength: 40,
  };
  const addressProps = {
    maxLength: 100,
  };
  const postCodeProps = {
    maxLength: 4,
  };
  const messageProps = {
    maxLength: 350,
  };

  const onPrivateInsuranceChange = (e) => {
    setHasPrivateInsurance(e.target.value === "yes");
  };

  const onSubmit = async (data) => {
    const patientFormPdf = await makePdf(data);
    const patientFormPdfFile = new File(
      [patientFormPdf],
      `patient-form-for-${data.firstNamePatient} ${data.lastNamePatient}`,
      {
        type: patientFormPdf.type,
      }
    );

    setSubmitting(true);

    data.requestPage = "Patient Info";
    let files;
    await getFiles([...uploadFile, patientFormPdfFile]).then(
      (customJsonFile) => {
        files = customJsonFile;
      }
    );
    data.files = files;
    const maxFileSize = 4194304;
    let totalFileSize = 0;
    files.map((item) => (totalFileSize = totalFileSize + item.fileSize));

    if (totalFileSize < maxFileSize) {
      await callEmailAPI(data).then(({ isSubmitting, response }) => {
        setSubmitting(isSubmitting);
        response.ok ? setOpenSucceed(true) : setOpenError(true);
      });
    } else {
      setOpenFileSize(true);
      setSubmitting(false);
      setUploadFile([]);
    }
    setTimeout(() => {
      window.open(URL.createObjectURL(patientFormPdf));
    }, 2000);
  };

  const resizeHanlder = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setSize({
      width,
      height,
    });
    if (width < 420 || (width > 590 && width < 790)) {
      setGender({
        male: "M",
        female: "F",
        nonBinary: "NB",
      });
    } else {
      setGender({
        male: "Male",
        female: "Female",
        nonBinary: "Non binary",
      });
    }
  };

  useEffect(() => {
    window.onresize = resizeHanlder;
    if (!size) {
      resizeHanlder();
    }
  }, []);

  return (
    <>
      <Breadcrumb pageTitle="Patient Form">
        <BreadcrumbItem
          Link="/patient-information/patient-form"
          Text="Patient Form"
        />
      </Breadcrumb>
      <PageContainer>
        <FormTitle>Patient Form</FormTitle>
        <Text>
          <strong>Welcome to Gippsland Orthodontics</strong>. Dr Adam Wallace
          and the team look forward to seeing you for your consultation in the
          near future. We kindly request that the below Patient Information form
          is completed prior to your initial consultation.
        </Text>
        <Text>
          If there are any questions regarding this form do not hesitate to{" "}
          <StyledLink to="/contact">contact us here</StyledLink>.
        </Text>

        <OutlindedFieldContainer>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <StyledFormContainer>
              <StyledGridContainer container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SectionTitle>Patient information</SectionTitle>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <StyledTextField
                    label="Preferred title"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="preferredTitlePatient"
                    error={errors.preferredTitlePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.preferredTitlePatient &&
                      "Preferred title is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4} md={5}>
                  <StyledTextField
                    label="First name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="firstNamePatient"
                    error={errors.firstNamePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.firstNamePatient && "First name is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4} md={5}>
                  <StyledTextField
                    label="Last name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="lastNamePatient"
                    error={errors.lastNamePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.lastNamePatient && "Last name is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    label="Age"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="agePatient"
                    error={errors.agePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.agePatient && "Age is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    id="dob"
                    label="Date of birth"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    name="dobPatient"
                    error={errors.dobPatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.dobPatient && "Date of birth is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <StyledRadioContainer>
                    <StyledRadioFormLabel>Sex</StyledRadioFormLabel>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: "Please select one" }}
                          control={control}
                          defaultValue=""
                          name="sex"
                          as={
                            <StyledSexRadioGroup>
                              <FormControlLabel
                                value="male"
                                control={<GreenRadio />}
                                label={gender.male}
                              />
                              <FormControlLabel
                                value="female"
                                control={<GreenRadio />}
                                label={gender.female}
                              />
                              <FormControlLabel
                                value="nonBinary"
                                control={<GreenRadio />}
                                label={gender.nonBinary}
                              />
                            </StyledSexRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                    </FormControl>
                  </StyledRadioContainer>
                  <span className="error-message">
                    {errors.sex && errors.sex.message}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    label="Home phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /[0][0-9]{9}/,
                    })}
                    inputProps={mobileProps}
                    name="phonePatient"
                    error={errors.phonePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.phonePatient &&
                      "10 digits phone number is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    label="Mobile phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /[0][0-9]{9}/,
                    })}
                    inputProps={mobileProps}
                    name="mobilePatient"
                    error={errors.mobilePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.mobilePatient &&
                      "10 digits mobile number is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    label="Email"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    name="emailPatient"
                    error={errors.emailPatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.emailPatient && "Email address is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <StyledTextField
                    label="Address"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 100 })}
                    inputProps={addressProps}
                    name="addressPatient"
                    error={errors.addressPatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.addressPatient && "Address is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label="City / Suburb"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="suburbPatient"
                    error={errors.suburbPatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.suburbPatient && "City / Suburb is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={Boolean(errors.statePatient)}
                  >
                    <InputLabel>State</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            Please select
                          </MenuItem>
                          <MenuItem value="VIC">VIC</MenuItem>
                          <MenuItem value="NSW">NSW</MenuItem>
                          <MenuItem value="ACT">ACT</MenuItem>
                          <MenuItem value="SA">SA</MenuItem>
                          <MenuItem value="TAS">TAS</MenuItem>
                          <MenuItem value="QLD">QLD</MenuItem>
                          <MenuItem value="WA">WA</MenuItem>
                          <MenuItem value="NT">NT</MenuItem>
                        </Select>
                      }
                      name="statePatient"
                      rules={{ required: "State is required" }}
                      control={control}
                      defaultValue=""
                    />
                    <FormHelperText>
                      {errors.statePatient && errors.statePatient.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    label="Postcode"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: true,
                      minLength: 4,
                      maxLength: 4,
                      pattern: /[0-9]{4}/,
                    })}
                    inputProps={postCodeProps}
                    name="postcodePatient"
                    error={errors.postcodePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.postcodePatient && "Postcode is required"}
                  </span>
                </Grid>
              </StyledGridContainer>
              <StyledGridContainer container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SectionTitle>Person responsible for accounts:</SectionTitle>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label="Name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="nameAccount"
                    error={errors.nameAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.nameAccount && "Name is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label="Relation"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="relationAccount"
                    error={errors.relationAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.relationAccount && "Relation is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    label="Home phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: false,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /[0][0-9]{9}/,
                    })}
                    inputProps={mobileProps}
                    name="phoneAccount"
                    error={errors.phoneAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.phoneAccount &&
                      "10 digits phone number is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    label="Work phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: false,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /[0][0-9]{9}/,
                    })}
                    inputProps={mobileProps}
                    name="workPhoneAccount"
                    error={errors.workPhoneAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.workPhoneAccount &&
                      "10 digits work phone is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledTextField
                    label="Mobile phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: false,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /[0][0-9]{9}/,
                    })}
                    inputProps={mobileProps}
                    name="mobileAccount"
                    error={errors.mobileAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.mobileAccount &&
                      "10 digits mobile number is required"}
                  </span>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <StyledTextField
                    label="Address"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 100 })}
                    inputProps={addressProps}
                    name="addressAccount"
                    error={errors.addressAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.addressAccount && "Address is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label="City / Suburb"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="suburbAccount"
                    error={errors.suburbAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.suburbAccount && "City / Suburb is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={Boolean(errors.stateAccount)}
                  >
                    <InputLabel>State</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="" disabled>
                            Please select
                          </MenuItem>
                          <MenuItem value="VIC">VIC</MenuItem>
                          <MenuItem value="NSW">NSW</MenuItem>
                          <MenuItem value="ACT">ACT</MenuItem>
                          <MenuItem value="SA">SA</MenuItem>
                          <MenuItem value="TAS">TAS</MenuItem>
                          <MenuItem value="QLD">QLD</MenuItem>
                          <MenuItem value="WA">WA</MenuItem>
                          <MenuItem value="NT">NT</MenuItem>
                        </Select>
                      }
                      name="stateAccount"
                      rules={{ required: false }}
                      control={control}
                      defaultValue=""
                    />
                    <FormHelperText>
                      {errors.stateAccount && errors.stateAccount.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    label="Postcode"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: false,
                      minLength: 4,
                      maxLength: 4,
                      pattern: /[0-9]{4}/,
                    })}
                    inputProps={postCodeProps}
                    name="postcodeAccount"
                    error={errors.postcodeAccount ? true : false}
                  />
                  <span className="error-message">
                    {errors.postcodeAccount && "Postcode is required"}
                  </span>
                </Grid>
              </StyledGridContainer>
              <StyledGridContainer container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>Do you have Private Health Insurance?</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <StyledCheckBoxContainer>
                    <FormControl
                      component="fieldset"
                      onChange={onPrivateInsuranceChange}
                    >
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="privateInsurance"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="no"
                                  control={<GreenRadio />}
                                  label="No"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="yes"
                                  control={<GreenRadio />}
                                  label="Yes"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.privateInsurance &&
                          errors.privateInsurance.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  hidden={!hasPrivateInsurance}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>If Yes</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={5} hidden={!hasPrivateInsurance}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="privateInsuranceType"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="hospital"
                                  control={<GreenRadio />}
                                  label="Hospital"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="extra"
                                  control={<GreenRadio />}
                                  label="Extra"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.privateInsuranceType &&
                          errors.privateInsuranceType.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StyledTextField
                    label="Dental Fund"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="dentalFund"
                    error={errors.dentalFund ? true : false}
                  />
                  <span className="error-message">
                    {errors.dentalFund && "Dental fund is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StyledTextField
                    label="Ref. Number"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="dentalFundRefNo"
                    error={errors.dentalFundRefNo ? true : false}
                  />
                  <span className="error-message">
                    {errors.dentalFundRefNo && "Ref. Number is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <StyledTextField
                    label="Medicare Number"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="medicareNo"
                    error={errors.medicareNo ? true : false}
                  />
                  <span className="error-message">
                    {errors.medicareNo && "Medicare number is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <StyledTextField
                    label="Pt#"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="medicareRefNo"
                    error={errors.medicareRefNo ? true : false}
                  />
                  <span className="error-message">
                    {errors.medicareRefNo && "Ref. Number is required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <StyledTextField
                    label="Expiry date"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="medicareExpiryDate"
                    error={errors.medicareExpiryDate ? true : false}
                  />
                  <span className="error-message">
                    {errors.medicareExpiryDate && "Expiry date is required"}
                  </span>
                </Grid>
              </StyledGridContainer>
              <StyledGridContainer container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <StyledTextField
                    label="Name(s) of other family member(s) treated by us"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="otherFamilyMember"
                    error={errors.otherFamilyMember ? true : false}
                  />
                  <span className="error-message">
                    {errors.otherFamilyMember && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <StyledTextField
                    label="Patient’s school or workplace"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="workplacePatient"
                    error={errors.workplacePatient ? true : false}
                  />
                  <span className="error-message">
                    {errors.workplacePatient && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StyledTextField
                    label="Patient referred by"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="referredBy"
                    error={errors.referredBy ? true : false}
                  />
                  <span className="error-message">
                    {errors.referredBy && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <StyledTextField
                    label="General Dentist"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="generalDentist"
                    error={errors.generalDentist ? true : false}
                  />
                  <span className="error-message">
                    {errors.generalDentist && "Required"}
                  </span>
                </Grid>
              </StyledGridContainer>
              <StyledGridContainer container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SectionTitle>
                    For child/adolescent patients only
                  </SectionTitle>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <StyledTextField
                    label="Mother’s name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="motherName"
                    error={errors.motherName ? true : false}
                  />
                  <span className="error-message">
                    {errors.motherName && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StyledTextField
                    label="Occupation"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="motherOccupation"
                    error={errors.motherOccupation ? true : false}
                  />
                  <span className="error-message">
                    {errors.motherOccupation && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <StyledTextField
                    label="Phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="motherPhone"
                    error={errors.motherPhone ? true : false}
                  />
                  <span className="error-message">
                    {errors.motherPhone && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <StyledTextField
                    label="Father’s name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="fatherName"
                    error={errors.fatherName ? true : false}
                  />
                  <span className="error-message">
                    {errors.fatherName && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StyledTextField
                    label="Occupation"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="fatherOccupation"
                    error={errors.fatherOccupation ? true : false}
                  />
                  <span className="error-message">
                    {errors.fatherOccupation && "Required"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <StyledTextField
                    label="Phone"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: false, maxLength: 40 })}
                    inputProps={nameProps}
                    name="fatherPhone"
                    error={errors.fatherPhone ? true : false}
                  />
                  <span className="error-message">
                    {errors.fatherPhone && "Required"}
                  </span>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>Parents are</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="parentsStatus"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                  value="married"
                                  control={<GreenRadio />}
                                  label="Married"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <FormControlLabel
                                  value="single"
                                  control={<GreenRadio />}
                                  label="Single"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <FormControlLabel
                                  value="divorced"
                                  control={<GreenRadio />}
                                  label="Separated/Divorced"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                  value="widowed"
                                  control={<GreenRadio />}
                                  label="Widowed"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.parentsStatus && errors.parentsStatus.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
              </StyledGridContainer>

              <StyledGridContainer container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SectionTitle>
                    Patient Medical and Dental History
                  </SectionTitle>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>
                      Please (x) any of these conditions that the patient has
                      had:
                    </span>
                  </CheckBoxTitle>
                </Grid>
                <StyledCheckBoxContainer>
                  <StyledFormGroup row>
                    {medicalHistory.map(({ name, label }) => (
                      <Grid item xs={12} sm={6} md={4} key={name}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              inputRef={register({ required: false })}
                              name={name}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    ))}
                  </StyledFormGroup>
                </StyledCheckBoxContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>If yes to any of the above please give details</span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="medicalHistoryComment"
                    error={errors.medicalHistoryComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.medicalHistoryComment &&
                      "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>What medication is the patient taking?</span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="medicationComment"
                    error={errors.medicationComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.medicationComment && "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>What dental treatment has the patient had?</span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="dentalComment"
                    error={errors.dentalComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.dentalComment && "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>Were there any difficulties with treatment?</span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="difficultyComment"
                    error={errors.difficultyComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.difficultyComment && "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>Have any teeth been extracted?</span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="teethExtractedComment"
                    error={errors.teethExtractedComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.teethExtractedComment &&
                      "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>
                      Have there been any accidents involving the teeth or jaws?
                    </span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="accidentsComment"
                    error={errors.accidentsComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.accidentsComment && "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid item xs={12} sm={12}>
                  <CheckBoxTitle>
                    <span>
                      Has the patient had orthodontic treatment before?
                    </span>
                  </CheckBoxTitle>
                </Grid>
                <StyledOtherContainer>
                  <StyledTextField
                    label=""
                    variant="outlined"
                    multiline
                    minRows={2}
                    type="text"
                    inputRef={register({
                      required: false,
                      maxLength: 350,
                    })}
                    inputProps={messageProps}
                    name="treatmentComment"
                    error={errors.treatmentComment ? true : false}
                  />
                  <span className="error-message">
                    {errors.treatmentComment && "Please provide your comments"}
                  </span>
                </StyledOtherContainer>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>Is the patient now</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="patientStatus"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                  value="growingRapidly"
                                  control={<GreenRadio />}
                                  label="growing rapidly"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <FormControlLabel
                                  value="growingModerately"
                                  control={<GreenRadio />}
                                  label="growing moderately"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <FormControlLabel
                                  value="stoppedGrowing"
                                  control={<GreenRadio />}
                                  label="stopped growing"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.patientStatus && errors.patientStatus.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>Women, are you pregnant?</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="pregnant"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="yes"
                                  control={<GreenRadio />}
                                  label="Yes"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="no"
                                  control={<GreenRadio />}
                                  label="No"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.pregnant && errors.pregnant.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>Does the patient smoke?</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="smoke"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="yes"
                                  control={<GreenRadio />}
                                  label="Yes"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="no"
                                  control={<GreenRadio />}
                                  label="No"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.smoke && errors.smoke.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>Does the patient drink alcohol regularly?</span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="alcohol"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="yes"
                                  control={<GreenRadio />}
                                  label="Yes"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="no"
                                  control={<GreenRadio />}
                                  label="No"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.alcohol && errors.alcohol.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className="checkBoxTitleWrapper"
                >
                  <CheckBoxTitle>
                    <span>
                      I would like to discuss the above questions in private
                    </span>
                  </CheckBoxTitle>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <StyledCheckBoxContainer>
                    <FormControl component="fieldset">
                      <StyledFormGroup row>
                        <Controller
                          rules={{ required: false }}
                          control={control}
                          defaultValue=""
                          name="discussPrivate"
                          as={
                            <StyledRadioGroup>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="yes"
                                  control={<GreenRadio />}
                                  label="Yes"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <FormControlLabel
                                  value="no"
                                  control={<GreenRadio />}
                                  label="No"
                                />
                              </Grid>
                            </StyledRadioGroup>
                          }
                        />
                      </StyledFormGroup>
                      <span className="error-message">
                        {errors.discussPrivate && errors.discussPrivate.message}
                      </span>
                    </FormControl>
                  </StyledCheckBoxContainer>
                </Grid>
              </StyledGridContainer>

              <StyledGridContainer container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label="Signature"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="signature"
                    error={errors.signature ? true : false}
                  />
                  <span className="error-message">
                    {errors.signature && "Please provide signature"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label="Date"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="signatureDate"
                    error={errors.signatureDate ? true : false}
                  />
                  <span className="error-message">
                    {errors.signatureDate && "Required"}
                  </span>
                </Grid>
              </StyledGridContainer>
            </StyledFormContainer>
            <Collapse in={openSucceed}>
              <StyledAlert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSucceed(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Email sent successfully
              </StyledAlert>
            </Collapse>
            <Collapse in={openError}>
              <StyledAlert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Error occurred. Please contact us.
              </StyledAlert>
            </Collapse>
            <Collapse in={openFileSize}>
              <StyledAlert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenFileSize(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Total file size should be less than 4MB. Please send files
                through an email.
              </StyledAlert>
            </Collapse>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress className={classes.top} />
                  ) : null}
                  Send
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </OutlindedFieldContainer>
      </PageContainer>
    </>
  );
};

export default PatientForm;
