import labelmake from "labelmake";
import downloadFile from "./GO-patient-form.pdf";

export const makePdf = async (data) => {
  const isChecked = (item) => {
    return item ? "X" : "";
  };

  const isRadioChecked = (value, key) => {
    return value === key ? "X" : "";
  };

  const basePdf = await fetch(downloadFile).then((res) => res.arrayBuffer());
  return (async () => {
    const template = {
      basePdf,
      schemas: [
        {
          preferredTitlePatient: {
            type: "text",
            position: {
              x: 155.61,
              y: 45.42,
            },
            width: 20.62,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          firstNamePatient: {
            type: "text",
            position: {
              x: 50.74,
              y: 45.42,
            },
            width: 46.54,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          lastNamePatient: {
            type: "text",
            position: {
              x: 98.82,
              y: 45.42,
            },
            width: 32,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          agePatient: {
            type: "text",
            position: {
              x: 34.08,
              y: 50.22,
            },
            width: 20,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          dobPatient: {
            type: "text",
            position: {
              x: 68.15,
              y: 50.22,
            },
            width: 32,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          sex: {
            type: "text",
            position: {
              x: 117.57,
              y: 50.22,
            },
            width: 32,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          addressPatient: {
            type: "text",
            position: {
              x: 50.84,
              y: 55.08,
            },
            width: 60.84,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          suburbPatientaddressAccount: {
            type: "text",
            position: {
              x: 114.55,
              y: 55.08,
            },
            width: 32,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          postcodePatient: {
            type: "text",
            position: {
              x: 162.84,
              y: 55.08,
            },
            width: 9.77,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          statePatient: {
            type: "text",
            position: {
              x: 150.69,
              y: 55.08,
            },
            width: 10.83,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          phonePatient: {
            type: "text",
            position: {
              x: 48.88,
              y: 59.6,
            },
            width: 40.2,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          mobilePatient: {
            type: "text",
            position: {
              x: 117.35,
              y: 59.6,
            },
            width: 40.2,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          emailPatient: {
            type: "text",
            position: {
              x: 49.89,
              y: 64,
            },
            width: 57.13,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          nameAccount: {
            type: "text",
            position: {
              x: 90.32,
              y: 69.2,
            },
            width: 39.67,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          relationAccount: {
            type: "text",
            position: {
              x: 148.21,
              y: 69.2,
            },
            width: 27.5,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          addressAccount: {
            type: "text",
            position: {
              x: 51.31,
              y: 74.28,
            },
            width: 61.9,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          suburbAccount: {
            type: "text",
            position: {
              x: 115.7,
              y: 74.28,
            },
            width: 33.59,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          stateAccount: {
            type: "text",
            position: {
              x: 150.73,
              y: 74.28,
            },
            width: 10.83,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          postcodeAccount: {
            type: "text",
            position: {
              x: 162.79,
              y: 74.28,
            },
            width: 9.77,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          phoneAccount: {
            type: "text",
            position: {
              x: 48.57,
              y: 78.8,
            },
            width: 30.14,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          workPhoneAccount: {
            type: "text",
            position: {
              x: 102.23,
              y: 78.8,
            },
            width: 30.14,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          mobileAccount: {
            type: "text",
            position: {
              x: 146.63,
              y: 78.8,
            },
            width: 30.14,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          privateInsuranceNo: {
            type: "text",
            position: {
              x: 86.62,
              y: 84.5,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          privateInsuranceYes: {
            type: "text",
            position: {
              x: 101.9,
              y: 84.5,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          hospital: {
            type: "text",
            position: {
              x: 126.8,
              y: 84.5,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          extra: {
            type: "text",
            position: {
              x: 152,
              y: 84.5,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          dentalFund: {
            type: "text",
            position: {
              x: 49.05,
              y: 88.4,
            },
            width: 33.84,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          dentalFundRefNo: {
            type: "text",
            position: {
              x: 109.59,
              y: 88.4,
            },
            width: 39.66,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          medicareNo: {
            type: "text",
            position: {
              x: 56.93,
              y: 93.4,
            },
            width: 44.16,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          medicareRefNo: {
            type: "text",
            position: {
              x: 111.64,
              y: 93.4,
            },
            width: 6.59,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          medicareExpiryDate: {
            type: "text",
            position: {
              x: 141.82,
              y: 93.4,
            },
            width: 36.75,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          otherFamilyMember: {
            type: "text",
            position: {
              x: 104.19,
              y: 98.37,
            },
            width: 44.95,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          workplacePatient: {
            type: "text",
            position: {
              x: 75.56,
              y: 103,
            },
            width: 96.01,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          referredBy: {
            type: "text",
            position: {
              x: 57.4,
              y: 107.8,
            },
            width: 44.16,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          generalDentist: {
            type: "text",
            position: {
              x: 53.91,
              y: 112.8,
            },
            width: 44.16,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          motherName: {
            type: "text",
            position: {
              x: 50.68,
              y: 122.5,
            },
            width: 26.05,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          motherOccupation: {
            type: "text",
            position: {
              x: 98.78,
              y: 122.5,
            },
            width: 28.02,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          motherPhone: {
            type: "text",
            position: {
              x: 141.06,
              y: 122.5,
            },
            width: 27.23,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          fatherName: {
            type: "text",
            position: {
              x: 51.68,
              y: 127.4,
            },
            width: 26.05,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          fatherOccupation: {
            type: "text",
            position: {
              x: 100.05,
              y: 127.4,
            },
            width: 28.02,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          fatherPhone: {
            type: "text",
            position: {
              x: 142.59,
              y: 127.4,
            },
            width: 27.23,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          married: {
            type: "text",
            position: {
              x: 59.66,
              y: 132.72,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          single: {
            type: "text",
            position: {
              x: 76.62,
              y: 132.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          divorced: {
            type: "text",
            position: {
              x: 114.62,
              y: 132.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          widowed: {
            type: "text",
            position: {
              x: 137,
              y: 132.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          asthma: {
            type: "text",
            position: {
              x: 31.62,
              y: 152,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          diabetes: {
            type: "text",
            position: {
              x: 88.62,
              y: 152,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          learningDifficulties: {
            type: "text",
            position: {
              x: 145.84,
              y: 152,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          allergies: {
            type: "text",
            position: {
              x: 31.62,
              y: 156.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          birthDefects: {
            type: "text",
            position: {
              x: 31.62,
              y: 166.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          bleedingDisorders: {
            type: "text",
            position: {
              x: 31.62,
              y: 171.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          boneDisorders: {
            type: "text",
            position: {
              x: 31.62,
              y: 176.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          kidneyDisease: {
            type: "text",
            position: {
              x: 88.62,
              y: 185.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          breathingDifficulties: {
            type: "text",
            position: {
              x: 31.62,
              y: 181.16,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          facialPain: {
            type: "text",
            position: {
              x: 88.62,
              y: 156.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          heartDisease: {
            type: "text",
            position: {
              x: 88.62,
              y: 166.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          jawJointProblems: {
            type: "text",
            position: {
              x: 88.62,
              y: 180.9,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          hiv: {
            type: "text",
            position: {
              x: 88.62,
              y: 176.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          hepatitis: {
            type: "text",
            position: {
              x: 88.62,
              y: 171.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          growthProblems: {
            type: "text",
            position: {
              x: 88.62,
              y: 161.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          psychologicalDisorders: {
            type: "text",
            position: {
              x: 145.84,
              y: 161.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          snoring: {
            type: "text",
            position: {
              x: 145.84,
              y: 176.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          rheumaticFever: {
            type: "text",
            position: {
              x: 145.84,
              y: 171.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          otherConditions: {
            type: "text",
            position: {
              x: 145.84,
              y: 190.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          speechProblems: {
            type: "text",
            position: {
              x: 145.84,
              y: 185.8,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          medicalHistoryComment: {
            type: "text",
            position: {
              x: 26.49,
              y: 199.31,
            },
            width: 158.19,
            height: 5,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          medicationComment: {
            type: "text",
            position: {
              x: 88.35,
              y: 204.28,
            },
            width: 95.74,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          dentalComment: {
            type: "text",
            position: {
              x: 96.23,
              y: 208.99,
            },
            width: 86.21,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          difficultyComment: {
            type: "text",
            position: {
              x: 93.79,
              y: 213.96,
            },
            width: 88.33,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          teethExtractedComment: {
            type: "text",
            position: {
              x: 78.13,
              y: 218.9,
            },
            width: 104.47,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          accidentsComment: {
            type: "text",
            position: {
              x: 26.22,
              y: 228.14,
            },
            width: 157.12,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          treatmentComment: {
            type: "text",
            position: {
              x: 107.49,
              y: 233.22,
            },
            width: 75.63,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          signature: {
            type: "text",
            position: {
              x: 43.14,
              y: 256.98,
            },
            width: 57.11,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          signatureDate: {
            type: "text",
            position: {
              x: 35.68,
              y: 261.69,
            },
            width: 48.11,
            height: 4.99,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          patientStatusRapidly: {
            type: "text",
            position: {
              x: 82.68,
              y: 238.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          patientStatusModerately: {
            type: "text",
            position: {
              x: 123.62,
              y: 238.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          patientStatusStopped: {
            type: "text",
            position: {
              x: 159.4,
              y: 238.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          pregnant: {
            type: "text",
            position: {
              x: 70.62,
              y: 243.5,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          pregnantNo: {
            type: "text",
            position: {
              x: 77.62,
              y: 243.6,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          smoke: {
            type: "text",
            position: {
              x: 66.62,
              y: 248.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          smokeNo: {
            type: "text",
            position: {
              x: 73.62,
              y: 248.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          alcohol: {
            type: "text",
            position: {
              x: 167.62,
              y: 248.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          alcoholNo: {
            type: "text",
            position: {
              x: 174.62,
              y: 248.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          discussPrivate: {
            type: "text",
            position: {
              x: 113.62,
              y: 253.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
          discussPrivateNo: {
            type: "text",
            position: {
              x: 120.62,
              y: 253.2,
            },
            width: 5,
            height: 3,
            alignment: "left",
            fontSize: 9,
            characterSpacing: 0,
            lineHeight: 1,
            fontColor: "#3f3f3f",
          },
        },
      ],
    };
    const {
      preferredTitlePatient,
      firstNamePatient,
      lastNamePatient,
      agePatient,
      dobPatient,
      sex,
      addressPatient,
      suburbPatientaddressAccount,
      postcodePatient,
      statePatient,
      phonePatient,
      mobilePatient,
      emailPatient,
      nameAccount,
      relationAccount,
      addressAccount,
      suburbAccount,
      stateAccount,
      postcodeAccount,
      phoneAccount,
      workPhoneAccount,
      mobileAccount,
      privateInsurance,

      privateInsuranceType,
      dentalFund,
      dentalFundRefNo,
      medicareNo,
      medicareRefNo,
      medicareExpiryDate,
      otherFamilyMember,
      workplacePatient,
      referredBy,
      generalDentist,
      motherName,
      motherOccupation,
      motherPhone,
      fatherName,
      fatherOccupation,
      fatherPhone,
      parentsStatus,
      asthma,
      diabetes,
      learningDifficulties,
      allergies,
      birthDefects,
      bleedingDisorders,
      boneDisorders,
      kidneyDisease,
      breathingDifficulties,
      facialPain,
      heartDisease,
      jawJointProblems,
      hiv,
      hepatitis,
      growthProblems,
      psychologicalDisorders,
      snoring,
      rheumaticFever,
      otherConditions,
      speechProblems,
      medicalHistoryComment,
      medicationComment,
      dentalComment,
      difficultyComment,
      teethExtractedComment,
      accidentsComment,
      treatmentComment,
      signature,
      signatureDate,
      patientStatus,
      pregnant,
      smoke,
      alcohol,
      discussPrivate,
    } = data;
    const inputs = [
      {
        preferredTitlePatient,
        firstNamePatient,
        lastNamePatient,
        agePatient,
        dobPatient,
        sex,
        addressPatient,
        suburbPatientaddressAccount,
        postcodePatient,
        statePatient,
        phonePatient,
        mobilePatient,
        emailPatient,
        nameAccount,
        relationAccount,
        addressAccount,
        suburbAccount,
        stateAccount,
        postcodeAccount,
        phoneAccount,
        workPhoneAccount,
        mobileAccount,
        privateInsuranceNo: isRadioChecked(privateInsurance, "no"),
        privateInsuranceYes: isRadioChecked(privateInsurance, "yes"),
        hospital: isRadioChecked(privateInsuranceType, "hospital"),
        extra: isRadioChecked(privateInsuranceType, "extra"),
        dentalFund,
        dentalFundRefNo,
        medicareNo,
        medicareRefNo,
        medicareExpiryDate,
        otherFamilyMember,
        workplacePatient,
        referredBy,
        generalDentist,
        motherName,
        motherOccupation,
        motherPhone,
        fatherName,
        fatherOccupation,
        fatherPhone,
        married: isRadioChecked(parentsStatus, "married"),
        single: isRadioChecked(parentsStatus, "single"),
        divorced: isRadioChecked(parentsStatus, "divorced"),
        widowed: isRadioChecked(parentsStatus, "widowed"),
        asthma: isChecked(asthma),
        diabetes: isChecked(diabetes),
        learningDifficulties: isChecked(learningDifficulties),
        allergies: isChecked(allergies),
        birthDefects: isChecked(birthDefects),
        bleedingDisorders: isChecked(bleedingDisorders),
        boneDisorders: isChecked(boneDisorders),
        kidneyDisease: isChecked(kidneyDisease),
        breathingDifficulties: isChecked(breathingDifficulties),
        facialPain: isChecked(facialPain),
        heartDisease: isChecked(heartDisease),
        jawJointProblems: isChecked(jawJointProblems),
        hiv: isChecked(hiv),
        hepatitis: isChecked(hepatitis),
        growthProblems: isChecked(growthProblems),
        psychologicalDisorders: isChecked(psychologicalDisorders),
        snoring: isChecked(snoring),
        rheumaticFever: isChecked(rheumaticFever),
        otherConditions: isChecked(otherConditions),
        speechProblems: isChecked(speechProblems),
        medicalHistoryComment,
        medicationComment,
        dentalComment,
        difficultyComment,
        teethExtractedComment,
        accidentsComment,
        treatmentComment,
        signature,
        signatureDate,
        patientStatusRapidly: isRadioChecked(patientStatus, "growingRapidly"),
        patientStatusModerately: isRadioChecked(
          patientStatus,
          "growingModerately"
        ),
        patientStatusStopped: isRadioChecked(patientStatus, "stoppedGrowing"),
        pregnant: isRadioChecked(pregnant, "yes"),
        pregnantNo: isRadioChecked(pregnant, "no"),
        smoke: isRadioChecked(smoke, "yes"),
        smokeNo: isRadioChecked(smoke, "no"),
        alcohol: isRadioChecked(alcohol, "yes"),
        alcoholNo: isRadioChecked(alcohol, "no"),
        discussPrivate: isRadioChecked(discussPrivate, "yes"),
        discussPrivateNo: isRadioChecked(discussPrivate, "no"),
      },
    ];
    const pdf = await labelmake({ template, inputs });

    // Node.js
    // fs.writeFileSync(path.join(__dirname, 'test.pdf'), pdf);

    // Browser
    const blob = new Blob([pdf.buffer], { type: "application/pdf" });
    // window.open(URL.createObjectURL(blob));
    return blob;
  })();
};
