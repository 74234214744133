import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import PatientForm from "../../components/patient-form";

const PatientFormPage = () => (
  <Layout>
    <SEO title="Patient form" />
    <PatientForm />
  </Layout>
);

export default PatientFormPage;
