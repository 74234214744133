export const medicalHistory = [
  {
    name: "asthma",
    label: "Asthma",
  },
  {
    name: "diabetes",
    label: "Diabetes",
  },
  {
    name: "learningDifficulties",
    label: "Learning difficulties or similar",
  },
  {
    name: "allergies",
    label: "Allergies (drug or other)",
  },
  {
    name: "facialPain",
    label: "Facial pain",
  },
  {
    name: "psychologicalDisorders",
    label: "Psychological disorders",
  },
  {
    name: "birthDefects",
    label: "Birth defects",
  },
  {
    name: "growthProblems",
    label: "Growth problems",
  },
  {
    name: "rheumaticFever",
    label: "Rheumatic fever",
  },
  {
    name: "bleedingDisorders",
    label: "Bleeding disorders",
  },
  {
    name: "heartDisease",
    label: "Heart disease",
  },
  {
    name: "snoring",
    label: "Snoring or sleep apnea",
  },

  {
    name: "boneDisorders",
    label: "Bone disorders",
  },
  {
    name: "hepatitis",
    label: "Hepatitis",
  },
  {
    name: "speechProblems",
    label: "Speech problems",
  },
  {
    name: "breathingDifficulties",
    label: "Breathing difficulties",
  },
  {
    name: "hiv",
    label: "HIV/AIDS",
  },
  {
    name: "otherConditions",
    label: "Other conditions",
  },

  {
    name: "kidneyDisease",
    label: "Kidney disease",
  },

  {
    name: "jawJointProblems",
    label: "Jaw joint problems",
  },
];
